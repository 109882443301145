var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "brush-size" },
    [
      _c("div", { staticClass: "brush-size__title" }, [_vm._v("Brush size")]),
      _vm._l(_vm.sizes, function (size) {
        return _c(
          "div",
          {
            key: size,
            staticClass: "brush-size__item",
            on: {
              click: function ($event) {
                return _vm.$emit("input", size)
              },
            },
          },
          [
            _c("div", {
              staticClass: "brush-size__item-body",
              class: {
                "brush-size__item-body--selected": size === _vm.selectedSize,
              },
              style: { height: size + "px", width: size + "px" },
            }),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }