















import Vue from "vue"
export default Vue.extend({
  name: "ColorPalette",
  model: {
    prop: "currentColor",
    event: "input"
  },
  props: {
    colors: {
      type: Array,
      default() {
        return [
          [0, 0, 0], //rgb(0, 0, 0);
          [255, 255, 255], //rgb(255, 255, 255);
          [215, 36, 36], //rgb(215, 36, 36);
          [215, 57, 100], //rgb(215, 57, 100)
          [141, 47, 36], //rgb(141, 47, 36)

          [96, 65, 176], //rgb(96, 65, 176)
          [65, 83, 175], //rgb(65, 83, 175)
          [251, 142, 210], //rgb(251, 142, 210)
          [72, 168, 238], //rgb(72, 168, 238)
          [82, 186, 209], //rgb(82, 186, 209)

          [65, 147, 136], //rgb(65, 147, 136)
          [103, 172, 91], //rgb(103, 172, 91)
          [151, 192, 92], //rgb(151, 192, 92)
          [209, 218, 89], //rgb(209, 218, 89)
          [252, 234, 96], //rgb(252, 234, 96)

          [246, 193, 68], //rgb(246, 193, 68)
          [242, 156, 56], //rgb(242, 156, 56)
          [199, 163, 134], //rgb(199, 163, 134)
          [116, 86, 74], //rgb(116, 86, 74)
          [158, 158, 158] //rgb(158, 158, 158)
        ]
      }
    },
    currentColor: {
      type: String,
      default: "rgb(0,0,0)"
    }
  },
  methods: {
    getColor([r, g, b]) {
      return `rgba(${r},${g},${b},1)`
    },
    isColorSelected(color) {
      return (
        this.currentColor
          .replace("rgb(", "")
          .replace(")", "")
          .replace(/\s/g, "") === color.join()
      )
    }
  }
})
