var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "color-palette" },
    _vm._l(_vm.colors, function (color) {
      return _c("div", {
        key: color.toString(),
        staticClass: "color-palette__item",
        class: {
          "color-palette__item--selected": _vm.isColorSelected(color),
        },
        style: { backgroundColor: _vm.getColor(color) },
        on: {
          click: function ($event) {
            return _vm.$emit("input", color)
          },
        },
      })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }